/* Estilos para pantallas pequeñas (cuando el menú hamburguesa está activo) */
.header {
  padding: 0.4rem;
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.links {
  display: none; /* Menú oculto por defecto */
}
.encabezado {
  display: none;
}

.encabezado-movil {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  color: aliceblue;
  z-index: 10000;
}

.logo {
  margin-left: 15px;
  z-index: 12000;
}

.logo img {
  width: 40px;
  object-fit: cover;
}

.desactive {
  display: block; /* Se muestra el menú cuando está activo */
  width: 100%;
  position: absolute;
  transform: translateY(-150px);
  text-align: center;
  list-style: none;
  margin: 0;
  top: 0;
  left: 0;
  transition: all 300ms;
  background-color: black;
  color: aliceblue;
  z-index: 2;
}

.actives {
  display: block; /* Se muestra el menú cuando está activo */
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  text-align: center;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.897);
  margin: 0;
  padding: 0;
  transition: all 300ms;
  z-index: 20000;
}

.actives li {
  margin: 10px 0;
}

.actives li a {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  text-decoration: none;
  animation: desvanecer 2s forwards;
}

.desactive li a {
  color: rgb(255, 255, 255);
  font-size: 2rem;
  position: relative;
  top: -500px;
  text-decoration: none;
  animation: desvanecer2 250ms forwards;
}

.burgerButton {
  display: block; /* Mostrar el botón hamburguesa */
  position: relative;
  z-index: 1000;
}

@keyframes desvanecer {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes desvanecer2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 1024px) {
  .header {
    padding: 0.4rem;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header h2 {
    font-weight: 400;
    color: white;
  }

  .header h2 span {
    font-weight: bold;
    color: white;
  }

  .logo {
    margin: 0;
    padding: 0;
    position: relative;
    top: 0;
    left: 50px;
  }

  .logo img{

    width: 70px;
  }

  .encabezado-movil {
    display: none;
  }

  .redes {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 5px;
  }

  .redes img {
    display: none;
    margin: auto 10px;
    object-fit: cover;
    max-width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 5px;
    padding: 2px;
    color: white;
    background-color: red;
  }

  .encabezado {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    color: aliceblue;
    z-index: 10000;
  }

  .title-links {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .title img {
    width: 80px;
  }

  .burgerButton {
    display: none; /* Se oculta el botón hamburguesa en pantallas grandes */
  }

  .actives,
  .desactive {
    display: none;
  }

  .links {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  .links li {
    margin: 10px 1rem;
  }

  .links li a {
    text-decoration: none;
    color: white;
    z-index: 12000;
    font-size: 20px;
  }
}
