/*-----------------------------------
    1 - Trust
------------------------------------*/
.trust {
  padding: 75px 0;
  background-color: #fafafa;
  color: #ddd;
  position: relative;
}

.trust .title {
  margin-bottom: 46px;
}

.trust h1 {
  color: #000000;
}

.trust h5 {
  font-size: 24px;
  color: #fefefe;
  margin: 28px 0 12px;
}

.trust p {
  font-size: 16px;
  color: #000000;
  margin-bottom: 0;
}

.ejemplosCard {
  margin-top: 25px !important;
  transform: translateX(-2000px);
}

@keyframes apareceDesdeIzq {
  0% {
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(0px);
  }
}

.ejemplos {
  height: auto;
  width: 100%;
  position: relative;
  margin: 5px;
  overflow: hidden;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  border-radius: 8px;
}



.ejemplos:hover{
  transform: rotate(0deg) scale(1.05);
}


.ejemplos img {
  object-fit: cover;
  width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out;
  position: relative;
  z-index: 1;
}



@media (min-width: 750px) {
}

@media (min-width: 1224px) {

  
}
