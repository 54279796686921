.hamburger-box{
  width: 80px;
  height:80px;
  display:flex;
  justify-content:center;
  align-items:center;
}

 
.line{
  width:100%;
  height:20%;
  margin-left:auto;
  margin-right:auto;
  margin-top:.5vw;
  margin-bottom:.5vw;
  display:flex;
  background: transparent;
  transition: all 0.3s ease-in-out;

}


.hamburger-icon.is-active .line.seven:nth-child(1){
  transform: translateX(-1.5vw) translateY(.2vw) rotate(-45deg) ;
  width:60%;
  transition: all 0.6s ease-in-out;  
}
.hamburger-icon.is-active .line.seven:nth-child(3){
  transform: translateX(-1.5vw) translateY(-.2vw) rotate(45deg);
  width:60%;
  transition: all 0.6s ease-in-out;
}
.hamburger-icon.is-active .line.seven:nth-child(2){
  opacity:0;
  transform: translateX(10vw);
  width:0%;
  transition: all 0.6s ease-in-out;
}


/*TEST*/

.ham {
  cursor: pointer;
  transition: transform 400ms;
}
.hamRotate.active {
  transform: rotate(45deg);
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke:#26cabd;
  stroke-width:5.5;
  stroke-linecap:round;
}


.ham1 .top {
  stroke-dasharray: 40 139;
}
.ham1 .bottom {
  stroke-dasharray: 40 180;
}
.ham1.active .top {
  stroke-dashoffset: -98px;
}
.ham1.active .bottom {
  stroke-dashoffset: -138px;
}