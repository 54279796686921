

/*-----------------------------------
    1 - Testimonials
------------------------------------*/
.testimonial-and-clients {
    background: #ee1515;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
  }
  
  .testimonials {
    padding: 95px 0 85px;
    color: #fff;
    border-bottom: 1px solid #26264b;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .testimonials .test-img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .testimonials .test-img img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    height: 90px;
  }
  
  .testimonials h5 {
    color: #26264b;
    margin-top: 15px;
  }
  
  .testimonials span {
    color: #c4c4c4;
    display: inline-block;
    margin: 0 0 17px;
  }
  
  .testimonials p {
    font-size: 17px;
    line-height: 27px;
  }
  
  .test-pagination {
    text-align: center;
    margin-top: 8px;
  }
  
  .test-pagination span {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background-color: #26264b;
    margin-bottom: 0;
    transition: all 0.2s ease-in-out;
  }
  
  .test-pagination span:hover {
    cursor: pointer;
  }
  
  .test-pagination span:not(:last-child) {
    margin-right: 10px;
  }
  
  .test-pagination span.swiper-pagination-bullet-active {
    width: 12px;
    height: 12px;
    background-color: white;
  }

  
@media (min-width: 1024px) {


}