/*-----------------------------------
    1 - Pricing Table
------------------------------------*/
#precios {
  background-color: rgb(32, 32, 32);
  position: relative;
  z-index: 1;
}

#precios::before {
  content: "";
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: rgb(15, 15, 15);
  clip-path:polygon(0 8%, 100% 6%, 100% 88%, 28% 89%, 0 96%);

}

.title {
 position: relative;
}

.title h2{
  color: white !important;
}

.pricing-table {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.single-pricing {
  padding: 65px 0 40px;
  margin: 23px auto 0 auto;
  color: #fff;
  background: #000000;
  border: 0px solid #fff;
  border-radius: 25px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.2s ease-in-out;
  box-shadow: 5px 5px 7px 1px #2bffb186;
  max-width: 400px;
}

.single-pricing:hover {
  transform: translateY(-10px);
}

.single-pricing span {
  font-size: 12px;
  text-transform: uppercase;
  padding: 6px 15px;
  border-radius: 15px;
  background-color: transparent;
  position: absolute;
  top: 28px;
  right: 6px;
  transition: all 0.2s ease-in-out;
}

.single-pricing h2 {
  margin-bottom: -5px;
}

.single-pricing .desc {
  color: #9d9d9d;
  padding-bottom: 30px;
  margin-bottom: 32px;
  border-bottom: 2px solid #2f2f2f;
  transition: all 0.2s ease-in-out;
}

.single-pricing .price {
  font-size: 2rem;
  margin-bottom: 8px;
}

.single-pricing p {
  color: #ddd;
  margin-top: 10px;
  font-size: 14px;
}

.single-pricing p:last-of-type {
  margin-bottom: 25px;
}

.single-pricing svg {
  width: 170px;
  position: absolute;
  top: -45px;
  right: -60px;
  z-index: -1;
  transition: all 0.5s ease-in-out;
}

.single-pricing:hover span {
  background-color: #2bffb1;
  color: black;
}

.single-pricing:hover .btn {
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  background-color: #094181 !important;
  color: white !important;
}

.precioCard {
  transform: translateX(-2000px);
}

@keyframes apareceDesdeIzq {
  0% {
    transform: translateX(-2000px);
  }
  100% {
    transform: translateX(0px);
  }
}



@media (min-width: 750px) {
  single-pricing {
    padding: 65px 0 40px;
    margin-top: 23px;
    color: #fff;
    background: #000000;
    border: 0px solid #fff;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }

}


@media (min-width: 1224px) {
  single-pricing {
    padding: 65px 0 40px;
    margin-top: 23px;
    color: #fff;
    background: #000000;
    border: 0px solid #fff;
    border-radius: 25px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all 0.2s ease-in-out;
  }


  .single-pricing p {
    color: #ddd;
    margin-top: 10px;
    font-size: 16px;
  }
  
}
