/*-----------------------------------
    1 - Featured
------------------------------------*/
.featured {
  padding: 80px 0;
  background-color: #fafafa;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}




.featured p {
  margin: 35px 0 53px;
}

.featured .media i {
  font-size: 24px;
  color: #ee1515;
}

.featured .media h5 {
  text-transform: uppercase;
  color: #26264b;
}

.featured .btn {
  margin-top: 80px;
}

.featured-small {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 25px 0 0;
  background-color: #fff;
}

.title-small {
  color: #0259ba;
}

.btn-ngDesign {
  background-color: #2bffb1 !important;
  color: rgb(0, 0, 0) !important;
}

.aos-animate {
  transform: translateX(-1200px);
  animation: traslado 1200ms ease-in-out forwards;
}

.aos-animate-right {
  transform: translateX(1200px);
  animation: trasladoRight 1200ms ease-in-out forwards;
}

.featured-big {
  display: none;
}

@keyframes traslado {
  0% {
    transform: translateX(-1200px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes trasladoRight {
  0% {
    transform: translateX(1200px);
  }
  100% {
    transform: translateX(0px);
  }
}

@media (min-width: 800px) {
  .featured-big {
    display: block;
    height: 500px;
    object-fit: cover;
    border-radius: 5px;
  }

  .boxImg {
    width: 100%;
    background-color: red;
  }
}

@media (min-width: 1024px) {
  .featured-big {
    height: 700px;
    object-fit: cover;
    border-radius: 15px;
  }

  .boxImg {
    width: 100%;
    background-color: red;
  }
}
