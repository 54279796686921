.hero {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 15px 20px;
  position: relative;
}

.hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../img/fondo.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(18px); /* Aplica el desenfoque solo al fondo */
  z-index: 1; /* Lo envía detrás del contenido */
  animation: fondo 5s infinite linear;
}

@keyframes fondo {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 10px 0;
  
  }
  50% {
    background-position: 10px 10px;
  }
  75% {
    background-position: 0 10px;

  }
  100% {
    background-position: 0 0;
  }
}

.encabezado-hero {
  text-align: center;
  margin: 100px auto;
  z-index: 2;
  color: white;
  opacity: 0;
}

.hero .hero_ {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-bottom: 120px;
  z-index: 2;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  opacity: 0;
}

.Laptop,
.ipad,
.iphone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  margin-top: 50px !important;
}

.Laptop {
  display: flex;
  width: auto !important;
  height: auto !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MacbookAir1 {
  width: 230px;
  height: 140px;
  padding: 4px 4px 10px 4px;
  background-color: #0e0e0e;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 0.4px solid white;
  border-bottom: none;
}

.MacbookAir2 {
  width: 100%;
  background: white;
  border-radius: 3px;
}

.MacbookAir2 img {
  object-fit: cover;
  width: 100%;
}

.Rectangle1 {
  width: 250px;
  height: 8px;
  background: black;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
  border: 0.4px solid rgba(255, 255, 255, 0.562);
  border-top: none;
}

.ipad {
  width: auto !important;
  height: auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 25px;
}

.marco {
  width: 105px;
  height: 150px;
  padding: 3px;
  border: 0.4px solid white;
  background: black;
  border-radius: 19px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.IpadScreen {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.Ellipse1 {
  width: 15px;
  height: 5px;
  margin-top: -6px;
  background: black;
  border-radius: 15px;
}

.iphone {
  width: auto !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 25px;
}

.marcoIphone {
  width: 60px;
  height: 120px;
  padding: 3px;
  background: #000000;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4px solid white;
}

.iphoneScreen {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.iphoneScreen img {
  object-fit: cover;
  width: 100%;
}

.IpadScreen img {
  object-fit: cover;
  width: 100%;
}
.EllipseIphone {
  width: 12px;
  height: 4px;
  background: black;
  border-radius: 15px;
  margin-top: -6px;
}


@keyframes apareceHero {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes apareceHeroTitle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (min-width: 550px){

  .hero .hero_ {
    display: flex;
    flex-direction: row;
    width: auto;
    margin-bottom: 120px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
  }

  .Laptop,
.ipad,
.iphone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}
  

}

/*======================================================*/

@media (min-width: 1224px) {
  .hero_ {
    width: 100%;
  }

  .hero::before {
    background-position: var(--bg-position, 0% 0%);
  }

  .Laptop {
    display: flex;
    width: auto !important;
    height: auto !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  .MacbookAir1 {
    width: 458px;
    height: 280px;
    padding: 8px 8px 20px 8px;
    background: #393939;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    border: 0.4px solid white;
    border-bottom: none;
  }

  .MacbookAir2 {
    width: 100%;
    background: white;
    border-radius: 3px;
  }
  .MacbookAir2 img {
    object-fit: cover;
  }

  .Rectangle1 {
    width: 500px;
    height: 20px;
    background: black;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border: 0.4px solid rgba(255, 255, 255, 0.562);
    border-top: none;
  }

  .ipad {
    width: auto !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 25px;
  }

  .marco {
    width: 250px;
    height: 300px;
    padding: 3px;
    background: #000000;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.4px solid white;
  }

  .IpadScreen {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .IpadScreen img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  .iphone {
    width: auto !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto 25px;
  }

  .marcoIphone {
    width: 150px;
    height: 300px;
    padding: 3px;
    background: #000000;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.4px solid white;
  }

  .iphoneScreen {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: white;
    border-radius: 12px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
  }

  .iphoneScreen img {
    position: absolute;
    top: 0;
    object-fit: cover;
    width: 145px;
    height: auto;
  }

  .EllipseIphone {
    position: absolute;
    width: 30px;
    height: 10px;
    background: black;
    border-radius: 15px;
  }

  .ipad, .Laptop, .iphone{
    transition: transform 750ms ease-in-out;
  }

  .zoom{

    transform: scale(1.4)

  }
  .far{

    transform: scale(0.5) 

  }

  .e.center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); /* Esto lo centra entre los dispositivos */
    z-index: 10; /* Asegúrate de que esté encima de los otros dispositivos */
    transition: transform 0.3s ease; /* Si deseas una transición suave */
  }
}
