.home{

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

}

.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: white;
    padding: 15px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  }
  
  .whatsapp-button:hover {
    background-color: #1ebe5a;
    cursor: pointer;
  }
  