#contacto {
  background-color: #8b8b8b;
  color: rgb(0, 0, 0);
  margin: 0;
}

.encabezadoForm{

  width: 100%;
  height: auto;
  padding: 50px 0;
  text-align: center;
}

.encabezadoForm h2, .encabezadoForm p{

  margin-bottom: 15px;
  color: rgb(255, 255, 255);
  
}

.form {
  color: rgb(0, 0, 0);
  margin: auto;
  width: 80%;
}

.form input, .form textarea {
  background-color: rgb(190, 190, 190) !important;
}

.form input:focus, form textarea:focus {
  border: 3px solid #3278d3 !important;
  background-color: rgb(190, 190, 190);
  box-shadow: none;
}

.button{

    background-color: #3278d3 !important;
    border: none !important;
    max-width: 200px;
    color: rgb(255, 255, 255) !important;
}



@media (min-width: 1024px) {

  
}