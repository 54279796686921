
/*-----------------------------------
    1 - Recent Posts
------------------------------------*/
.recent-posts {
    padding: 65px 0 100px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .recent-posts .btn {
    margin-top: 60px;
  }
  
  .recent-posts .row .col-lg-6:nth-of-type(odd) .post-thumb {
    margin-top: 30px;
  }
  
  .recent-posts .row .col-lg-6:nth-of-type(even) .post-thumb {
    margin-bottom: 30px;
  }
  
  .single-rpost {
    margin: 35px 0 0;
  }
  
  .post-thumb {
    flex: 0 0 273px;
  }
  
  .post-content {
    color: #ccc;
  }
  
  .post-content time {
    font-size: 14px;
    color: #ccc;
  }
  
  .post-content h3 {
    margin: 10px 0 2px;
  }
  
  .post-content h3 a {
    color: #26264b;
    font-weight: 600;
  }
  
  .post-content h3 a:hover {
    font-weight: 700;
    text-decoration: none;
  }
  
  .post-content a {
    color: #ccc;
  }
  
  .post-content a:hover {
    color: #26264b;
  }
  
  .post-content .post-btn {
    color: #6e6e6e;
    text-align: center;
    font-size: 10px;
    display: inline-block;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50%;
    background-color: whitesmoke;
  }
  
  .post-content .post-btn:hover {
    color: #fff;
    background-color: #ee1515;
  }


  
@media (min-width: 1024px) {


}