
/*-----------------------------------
    1 - Footer Widgets
------------------------------------*/

.footer{

    background-color: #3f3f3e;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer-widgets {
    padding: 100px 0;
  }
  
  .footer-widgets .col-md-6 .single-widget:not(:first-child) .widget-tiltle {
    margin: 40px 0 30px;
  }
  
  .single-widget {
    color: #aaa;
  }
  
  .single-widget .widget-tiltle {
    font-size: 17px;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 45px;
  }
  
  .single-widget i {
    color: #ee1515;
  }
  
  .single-widget h6 {
    color: #fff;
  }
  
  .single-widget .media {
    margin-bottom: 20px;
  }

  .single-widget .media a {
    
    color:  #2BFFB1;
  }
  
  .contact-widget a {
    color: #aaa;
  }
  
  .contact-widget i {
    font-size: 22px;
  }
  
  .twitter-widget i,
  .twitter-widget h6 {
    color: #777;
  }
  
  span {
    color: #fff;
  }
  
  .recent-post-widget .rcnt-img {
    width: 68px;
    height: 68px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  .recent-post-widget .rcnt-img img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
  }
  
  .recent-post-widget a {
    color: #fff;
  }
  
  .recent-post-widget p {
    font-size: 13px;
  }
  
  .recent-post-widget i {
    margin-right: 10px;
  }
  
  .recent-post-widget i:last-of-type {
    margin-left: 15px;
  }
  
  .tags-widget {
    margin: -10px -10px 0 0;
  }
  
  .tags-widget a {
    color: #aaa;
    display: inline-block;
    padding: 10px 12px;
    border-radius: 2px;
    background-color: #363636;
    font-size: 14px;
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    margin: 10px 10px 0 0;
  }
  
  .tags-widget a:hover {
    text-decoration: none;
    color: #26264b;
    background-color: #ee1515;
  }
  
  .subscribe-widget form {
    margin-bottom: 10px;
  }
  
  .subscribe-widget input {
    font-size: 14px;
    padding: 0px 10px;
    background-color: #bebebe;
    border: none;
    line-height: 0;
    position: relative;
    z-index: 1;
  }
  
  .input-group input:focus {
    outline: none;
    box-shadow: none;
  }

  .input-group-btn {

    padding: 0;
    height: 45px;
    margin: 0;
  }
  
  .input-group-btn button {
    background: #2BFFB1;
    border: none;
    width: 35px;
    height: 45px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-left: -10px;
    position: relative;
    z-index: 5;
  }
  
  .subscribe-widget button i {
    color: #fff;
  }
  
  .subscribe-widget .social-nav {
    margin-top: 32px;
  }
  
  .subscribe-widget .social-nav a {
    display: inline-block;
    text-align: center;
    width: 36px;
    height: 36px;
    line-height: 36px;
    background-color: #fff;
    color: #ddd;
    background-color: #444;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    margin-right: 10px;
  }
  
  .subscribe-widget .social-nav a i {
    color: #ddd;
  }
  
  .subscribe-widget .social-nav a:hover {
    background-color: #ee1515;
  }
  
  /*-----------------------------------
      1 - Foot Note
  ------------------------------------*/
  .foot-note {
    padding: 35px 0;
    background-color: #000000;
    color: #ffffff;
  }
  
  .foot-note a {
    color: #2BFFB1;
  }
  
  .foot-note a:last-child {
    margin-left: 30px;
  }
  
  .foot-note a.fh5-link {
    margin-left: 0;
  }
  

  
@media (min-width: 1024px) {
.contact-widget{

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
}

}