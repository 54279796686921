/*-----------------------------------
    1 - Services
------------------------------------*/

.services {
  
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
}


.title {
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
}

.title h6 {
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.title h1 {
  font-size: 35px;
}

.title .title-blue {
  color: #26264b;
}

.title .title-primary {
  color: #ee1515;
}

.services {
  padding: 80px 0 50px;
}

.services .title {
  margin-bottom: 20px;
}

.services .media {
  margin: 40px 0;
}

.services h5 {
  color: #26264b;
  border-bottom: 2px solid #e7e7e7;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

.segmento{
 opacity: 0;
}

.mr-4{

  width: 2rem;
  margin-bottom: 1rem;
}


@keyframes aparece {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}



@media (min-width: 1024px) {

  

.mr-4{

  width: 5rem;
  margin-bottom: 1rem;
}

.media {

  display: flex;
  flex-direction: column;
  align-items: center;
}

}